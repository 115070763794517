import React from "react";

const Radios = ({
  value,
  name,
  id,
  label,
  placeholder,
  hint,
  options,
  optional,
  error,
  setFieldValue,
}) => {
  const handleChange = (e) => {
    const option = options.find(({ _id }) => _id === e.target.value);
    setFieldValue(name, option);
  };

  return (
    <div className=" w-full  mb-4">
      <p className={`${error && "text-red-800"} font-bold mb-3 text-lg`}>
        {label}
        <span className="text-red-800 text-2xl">{!optional && "*"}</span>
      </p>
      <div className="w-full bg-field p-6 mt-4">
        {hint && <p className="mb-3 text-lg">{hint}</p>}

        <div className="w-full flex flex-wrap">
          {options.map(({ label, _id }) => (
            <div className={`w-full mb-4 flex items-center`}>
              <input
                id={_id}
                type="radio"
                name={name}
                value={_id}
                onChange={handleChange}
                checked={value?._id === _id}
              />
              <label htmlFor={_id} className="ml-2">
                {label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>

    //   <label
    //     htmlFor={id}
    //     className={`${
    //       error && "text-red-800"
    //     } font-bold mb-1 flex items-center`}
    //   >
    //     {label}
    //     <span className="text-red-800 text-2xl flex items-end">
    //       {!optional && "*"}
    //     </span>
    //   </label>
    //   <select
    //     name={name}
    //     onChange={handleChange}
    //     value={value}
    //     id={id}
    //     className={`w-full py-2 px-2 outline-none ${
    //       !value ? "text-gray-500" : ""
    //     }`}
    //   >
    //     {!value && (
    //       <option value="">
    //         {placeholder ?? "Please select an option..."}
    //       </option>
    //     )}
    //     {options?.map(({ label, _id }) => {
    //       return <option label={label} value={_id} />;
    //     })}
    //   </select>
    //   <p className="mt-1 text-sm text-fieldtext">{hint}</p>
    // </div>
  );
};

export default Radios;
