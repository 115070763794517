import store from "@store";
import AxiosLibrary from "axios";

export const Axios = AxiosLibrary.create();
const CancelToken = AxiosLibrary.CancelToken;
let cancel;

export const requestCancelled = (error) => AxiosLibrary.isCancel(error);

export const makeGetRequest = async (url, params = {}, config = {}) => {
  try {
    cancel && cancel();
    const user = store.getState().user;
    const websiteId = process.env.websiteId;
    const token = user?.token;
    const authorization = token ? { Authorization: "Bearer " + token } : {};

    return await Axios.get(url, {
      params: { ...params, websiteId },
      headers: authorization,
      timeout: 15000,
      ...config,
    });
  } catch (error) {
    if (requestCancelled(error)) throw "cancelled";
    throw error?.response?.data;
  }
};

export const makePostRequest = async (url, data, params = {}, config = {}) => {
  try {
    cancel && cancel();
    params = !!params ? params : {};
    const user = store.getState().user;
    const websiteId = process.env.websiteId;
    const token = user?.token ?? "";
    const authorization = token ? { Authorization: "Bearer " + token } : {};

    return await Axios.post(url, data, {
      params: { ...params, websiteId },
      headers: authorization,
      ...config,
      timeout: 15000,
    });
  } catch (error) {
    if (requestCancelled(error)) throw "cancelled";
    throw error?.response?.data;
  }
};

export const makePutRequest = async (url, data, params = {}, config = {}) => {
  try {
    cancel && cancel();
    params = !!params ? params : {};
    const user = store.getState().user;
    const websiteId = process.env.websiteId;
    const token = user && user.token;
    const authorization = token ? { Authorization: "Bearer " + token } : {};

    return await Axios.put(url, data, {
      params: { ...params, websiteId },
      headers: { ...authorization },
      ...config,
      timeout: 15000,
    });
  } catch (error) {
    if (requestCancelled(error)) throw "cancelled";
    throw error?.response?.data;
  }
};

export const makeDeleteRequest = async (url, params = {}, config = {}) => {
  try {
    cancel && cancel();
    params = !!params ? params : {};
    const user = store.getState().user;
    const websiteId = process.env.websiteId;
    const token = user && user.token;
    const authorization = token ? { Authorization: "Bearer " + token } : {};

    return await Axios.delete(url, {
      params: { ...params, websiteId },
      headers: { ...authorization },
      ...config,
      timeout: 15000,
    });
  } catch (error) {
    if (requestCancelled(error)) throw "cancelled";
    throw error?.response?.data;
  }
};

Axios.interceptors.request.use((config) => {
  try {
    return { ...config, cancelToken: new CancelToken((c) => (cancel = c)) };
  } catch (error) {
    if (requestCancelled(error)) throw "cancelled";
    throw error?.response?.data;
  }
});
