export function setForm(website) {
    return {
        type: 'FORM_SET',
        payload: website,
    };
}

export function resetForm() {
    return {
        type: 'FORM_RESET',
    };
}
