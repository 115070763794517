import React, { useState, useEffect } from "react";

const Checkboxes = ({
  value,
  name,
  id,
  label,
  hint,
  options,
  optional,
  error,
  setFieldValue,
}) => {
  const handleChange = (e) => {
    const option = options.find(({ _id }) => {
      return _id === e.target.value;
    });
    setFieldValue(
      name,
      !value?.some(({ _id }) => _id === e.target.value)
        ? [...(value || []), option]
        : (value || []).filter(({ _id }) => _id !== e.target.value)
    );
  };

  return (
    <div className=" w-full  mb-4">
      <p className={`${error && "text-red-800"} font-bold mb-3 text-lg`}>
        {label}
        <span className="text-red-800 text-2xl">{!optional && "*"}</span>
      </p>

      <div className="w-full bg-field p-6 mt-4">
        {hint && <p className="mb-3 text-lg">{hint}</p>}

        <div className="w-full flex flex-wrap">
          {options?.map(({ label, _id }, index) => {
            return (
              <div
                className={`${
                  index === options.length - 1 ? "w-full" : "w-1/2"
                } mb-4 flex items-start`}
              >
                <label htmlFor={_id}>
                  <input
                    className="mt-2 mr-1"
                    checked={value?.some(
                      ({ _id: selectedId }) => selectedId === _id
                    )}
                    type="checkbox"
                    value={_id}
                    id={_id}
                    onChange={handleChange}
                  />

                  <span className="text-md ml-2">{label}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Checkboxes;
