export const getApiServer = () => {
  if (process.env.API_URL) {
    return process.env.API_URL;
  }

  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3001";
  }

  return "https://api.merlinpanel.com/v1";
};
const API_ROUTE = getApiServer();
export const SITE = `${API_ROUTE}/client/siteData`;
export const CONTACT = `${API_ROUTE}/client/contact`;
export const FORM = `${API_ROUTE}/client/form`;
