import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swiper from "react-id-swiper";
import React from "react";
import { useSelector } from "react-redux";

const Heading = ({
  handlePrevStep,
  handleNextStep,
  form,
  currentStep,
  currentStepIndex,
  setSwiper,
  completedPercentage,
}) => {
  const data = useSelector(({ form }) => form);

  const handleArrowLeft = () => {
    if (currentStepIndex === 0) return;
    handlePrevStep();
  };

  const handleArrowRight = () => {
    if (data?.length > currentStepIndex) {
      handleNextStep();
    }
  };

  return (
    <div className="mt-12  mx-auto max-w-6xl mb-24 px-5">
      <div className="relative py-2 px-10  bg-field">
        <div
          onClick={() => {
            handleArrowLeft();
          }}
          className={`absolute left-0 top-0 flex items-center ${
            currentStepIndex === 0 && "arrow-greyed"
          }`}
          style={{
            width: "20px",
            height: "100%",
            marginLeft: "10px",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </div>

        <div
          className={`absolute right-0 top-0 flex items-center ${
            data?.length <= currentStepIndex
              ? "arrow-greyed"
              : !data && "arrow-greyed"
          }`}
          style={{
            width: "20px",
            height: "100%",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            handleArrowRight();
          }}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </div>

        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          centeredSlides
          breakpoints={{
            320: {
              slidesPerView: 1,
            },

            600: {
              slidesPerView: 2,
            },

            1000: {
              slidesPerView: 3,
            },
          }}
          getSwiper={setSwiper}
        >
          {form?.sections.map(({ _id, title, description }, index) => (
            <div
              key={_id}
              className={`text-black font-medium ${
                currentStepIndex === index
                  ? "text-center"
                  : currentStepIndex === index - 1
                  ? "text-right"
                  : "text-left"
              } `}
            >
              <span className="font-bold"> {description} </span>
              <span className=""> - {title} </span>
            </div>
          ))}
          <div
            className={` text-black font-medium text-center
              
            } `}
          >
            <span className={`font-bold`}>
              {" "}
              Stage: {form?.sections?.length + 1}
            </span>
            <span className=""> - Summary</span>
          </div>
        </Swiper>
      </div>
      <div className="w-full h-2 relative bg-field mt-4 rounded-md">
        <div
          style={{ width: `${completedPercentage}%` }}
          className="bg-primary absolute left-0 h-full rounded-md"
        />
      </div>
    </div>
  );
};

export default Heading;
