import * as Yup from "yup";
import { Formik } from "formik";
import React, { Fragment, useState } from "react";
import Select from "@components/Form/Fields/Select";
import Input from "@components/Form/Fields/Input";
import Checkboxes from "@components/Form/Fields/Checkboxes";
import Radios from "@components/Form/Fields/Radios";
import { useStaticQuery } from "gatsby";

const renderValidationField = ({ type, label, optional }) => {
  let value;

  switch (type) {
    case "string":
    case "paragraph":
    case "dropdown":
    case "radio":
      value = Yup.string();
      if (!optional) value = value.required(`${label} is required.`);
      return value;
    case "checkbox":
      value = Yup.array().of(Yup.string());
      if (!optional) value = value.required(`${label} is required.`);
      return value;
  }
};

const renderValidationFields = (fields) => {
  const schema = {};

  Object.keys(fields).forEach((key) => {
    schema[fields[key]._id] = renderValidationField(fields[key]);
  });

  return Yup.object().shape(schema);
};

const Section = ({ section, data, handleNextStep, handleDelete }) => {
  const getFields = () => {
    if (section?.multiple) {
      return data?.fields;
    }

    const fields = {};

    for (let field of section.fields) {
      fields[field?._id] = data ? data?.fields?.[field?._id] : undefined;
    }

    return fields;
  };

  const renderValidation = () => {
    let validation;

    if (section?.multiple) {
      validation = Yup.array().of(renderValidationFields(section?.fields));

      if (section?.max) {
        validation = validation.max(
          section?.max,
          `You can't have more than ${section?.max} ${section?.title}.`
        );
      }

      if (section?.min) {
        validation = validation
          .min(
            section?.min,
            `You can't have less than ${section?.min} ${section?.title}.`
          )
          .required(`${section?.title} is required.`);
      }

      return validation;
    }

    return renderValidationFields(section?.fields);
  };

  return (
    <div className="max-w-6xl mx-auto px-5">
      <div className="mt-8">
        <Formik
          initialValues={{
            _id: section?._id,
            fields: getFields(),
          }}
          validationSchema={Yup.object().shape({
            _id: Yup.string(),
            fields: renderValidation(),
          })}
          onSubmit={handleNextStep}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => {
            const props = {
              section,
              fields: section?.fields,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              errors,
              data,
            };

            return (
              <div className="w-full">
                <div className="mt-4 flex w-full justify-between flex-wrap">
                  <Fragment>
                    {section?.multiple ? (
                      <Multiple {...props} />
                    ) : (
                      <Single {...props} />
                    )}

                    <div className="w-full mt-5 flex justify-between flex-wrap">
                      <button
                        className="bg-delete opacity-50 text-white w-full mb-3 md:mb-0 md:w-1/4 px-3  text-xl py-3"
                        type="button"
                        onClick={handleDelete}
                      >
                        Delete Application
                      </button>
                      <button
                        onClick={handleSubmit}
                        className="bg-primary text-white px-3 w-full mb-3 md:mb-0 md:w-1/4 text-xl py-3"
                      >
                        Save And Continue
                      </button>
                    </div>
                  </Fragment>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const Multiple = (props) => {
  const {
    section,
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    data,
  } = props;

  const { edit, cross } = useStaticQuery(graphql`
    query EditCross {
      edit: allFile(filter: { absolutePath: { regex: "/edit.svg/" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      cross: allFile(filter: { absolutePath: { regex: "/cross.svg/" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `);
  const Edit = edit.edges[0].node;
  const Cross = cross.edges[0].node;
  const [editing, setEditing] = useState(null);
  const [creating, setCreating] = useState(null);

  const handleSubmit = ({ fields }) => {
    const sectionIndex = editing
      ? values?.fields?.findIndex((field) => field === editing)
      : values?.fields?.length || 0;
    setFieldValue(`fields.${sectionIndex}`, fields);
    setEditing(false);
    setCreating(false);
  };

  const renderFields = (fields) => {
    const items = [];

    for (let id in fields) {
      if (fields.hasOwnProperty(id)) {
        const sectionField = section?.fields?.find(({ _id }) => _id === id);

        items.push(
          <Fragment>
            <div className="w-full md:w-1/3 mb-2 md:mb-4">
              {!!sectionField?.label && (
                <p>
                  <b>{sectionField?.label}</b>
                </p>
              )}
              <p>{fields?.[id]?.label ?? fields?.[id]}</p>
            </div>
          </Fragment>
        );
      }
    }

    return items;
  };

  return (
    <div className="w-full">
      {!values?.fields?.length && (
        <p className="mb-8 text-primary font-bold">
          {!!section?.min && (
            <Fragment>
              {`You must add at least ${section?.min} `}
              <span className="lowercase"> {section?.title}</span> to continue.
            </Fragment>
          )}

          {!!section?.max && (
            <Fragment>
              {`You can't have more than ${section?.max} `}
              <span className="lowercase"> {section?.title}</span> to continue.
            </Fragment>
          )}
        </p>
      )}

      <div
        className={`w-full flex flex-wrap ${
          Boolean(values?.fields?.length) && "mb-16"
        }`}
      >
        {values?.fields?.map((fields, index) => (
          <div key={index} className={`w-full mb-4`}>
            <div className="w-full bg-field py-6 pl-8 pr-4 border border-field flex flex-wrap h-full">
              <div className="w-4/5 flex flex-wrap h-full">
                {renderFields(fields)}
              </div>
              {/* <div className="w-1/5 flex flex-col h-full">
                 <div
                  className="bg-white py-3 px-4"
                  style={{ maxWidth: "60px" }}
                >
                  <FontAwesomeIcon
                    icon="pen"
                    onClick={() => setEditing(fields)}
                  />
                </div>
                <div
                  className="bg-white py-3 px-4"
                  style={{ maxWidth: "60px" }}
                >
                  <FontAwesomeIcon
                    icon="trash"
                    onClick={() =>
                      setFieldValue(
                        `fields`,
                        values?.fields?.filter((x, i) => i !== index)
                      )
                    }
                  />
                </div>

              </div> */}

              <div className="w-1/5">
                <div
                  className="bg-white py-3 px-4 mb-4"
                  style={{ maxWidth: "60px" }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => setEditing(fields)}
                    src={Edit.publicURL}
                  />
                </div>
                <div
                  className="bg-white py-3 px-4 my-4"
                  style={{ maxWidth: "60px" }}
                >
                  <img
                    onClick={() =>
                      setFieldValue(
                        `fields`,
                        values?.fields?.filter((x, i) => i !== index)
                      )
                    }
                    style={{ cursor: "pointer" }}
                    src={Cross.publicURL}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {(editing || creating) && (
        <Formik
          initialValues={{ fields: editing || undefined }}
          validationSchema={Yup.object().shape({
            fields: Yup.lazy(() => renderValidationFields(section?.fields)),
          })}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            console.log("touched", touched, "errors", errors);
            return (
              <Fragment>
                <div className="w-full flex flex-wrap justify-between">
                  <Single
                    fields={section?.fields}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="w-full flex justify-end">
                  {creating && (
                    <button
                      className="bg-green-500 text-white w-1/6 text-xl py-3"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Add
                    </button>
                  )}

                  {editing && (
                    <button
                      className="bg-green-500 text-white w-1/3 md:w-1/6 text-xl py-3"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  )}
                </div>
              </Fragment>
            );
          }}
        </Formik>
      )}

      {!editing && !creating && (
        <div
          className={`w-full md:w-1/2 md:pr-5  mb-4`}
          style={{ height: "200px" }}
        >
          <div className="border-dashed border h-full border-field flex flex-col items-center justify-center">
            <button
              style={{ outline: "none" }}
              className="font-bold text-lg outline-none"
              onClick={() => setCreating(true)}
            >
              Add new {section?.title} +
            </button>
          </div>
        </div>
      )}

      {touched?.fields &&
        errors?.fields &&
        typeof errors?.fields === "string" && (
          <span className="text-red-800">{errors?.fields}</span>
        )}
    </div>
  );
};

const Single = ({
  fields,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  errors,
}) => {
  return fields?.map((field) => {
    const { _id, type } = field;
    const name = `fields.${_id}`;

    const fieldProps = {
      ...field,
      name,
      value: values?.fields?.[_id],
      handleChange,
      handleBlur,
      setFieldValue,
      error: touched?.fields?.[_id]
        ? touched?.fields?.[_id] && errors?.fields?.[_id]
        : errors?.fields?.[_id],
      id: _id,
    };

    return (
      <Fragment key={_id}>
        {type === "string" && (
          <div className={` w-full field-input  mb-4`}>
            <Input {...fieldProps} />
          </div>
        )}
        {type === "paragraph" && (
          <div className={` w-full  field-input  mb-4`}>
            <Input {...fieldProps} textarea />
          </div>
        )}
        {type === "radio" && <Radios {...fieldProps} />}
        {type === "checkbox" && <Checkboxes {...fieldProps} />}
        {type === "dropdown" && (
          <div className="w-full  field-input  mb-4">
            <Select {...fieldProps} />
          </div>
        )}
      </Fragment>
    );
  });
};

export default Section;
