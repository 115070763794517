import React from "react";

const Select = ({
  value,
  name,
  id,
  label,
  placeholder,
  hint,
  options,
  optional,
  error,
  handleBlur,
  setFieldValue,
}) => {
  const handleChange = (e) => {
    const option = options.find(({ _id }) => _id === e.target.value);
    setFieldValue(name, option);
  };

  return (
    <div className="pt-2 pb-5 px-4 bg-field w-full h-full flex flex-col text-black">
      <label
        htmlFor={id}
        className={`${
          error && "text-red-800"
        } font-bold mb-1 flex items-center`}
      >
        {label}
        <span className="text-red-800 text-2xl flex items-end">
          {!optional && "*"}
        </span>
      </label>
      <select
        onBlur={handleBlur}
        name={name}
        onChange={handleChange}
        value={value?._id}
        id={id}
        className={`w-full py-2 px-2 outline-none ${
          !value ? "text-gray-500" : ""
        }`}
      >
        {!value && (
          <option value="">
            {placeholder ?? "Please select an option..."}
          </option>
        )}
        {options?.map(({ label, _id }) => (
          <option key={_id} label={label} value={_id} />
        ))}
      </select>
      <p className="mt-1 text-sm text-fieldtext">{hint}</p>
    </div>
  );
};

export default Select;
