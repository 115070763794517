import React, { useState, useEffect, Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ContactCard from "@components/contactCard";
import ConfirmBox from "react-dialog-confirm";
import { makePostRequest } from "@helpers/requests";
import { FORM, SENDFORM } from "@helpers/api";
import { useSelector } from "react-redux";
const Summary = ({ handleStep, handleReset, form, onSubmit }) => {
  const data = useSelector(({ form }) => form);
  const { edit, merlinWebsite } = useStaticQuery(graphql`
    query Edit {
      edit: allFile(filter: { absolutePath: { regex: "/edit.svg/" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      merlinWebsite {
        _id
      }
    }
  `);

  const Edit = edit.edges[0].node;
  const [deleting, setDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // const handleCancelSubmit = async () => {
  //   try {
  //     let sectionsWithId = Array.from({ length: sections.length }, (i) => ({}));
  //     let storedSections = BasicInfo.sections;
  //
  //     storedSections.map((section, index) => {
  //       sectionsWithId[index]._id = sections[index]._id;
  //
  //       const customFieldsData = [];
  //       for (let keyparent in section) {
  //         for (let key in section[keyparent]) {
  //           console.log(section[keyparent]);
  //           if (key.length === 24) {
  //             customFieldsData.push({
  //               _id: key,
  //               value: section[keyparent][key],
  //               label: section[keyparent].label,
  //             });
  //           }
  //         }
  //       }
  //
  //       if (sections[index].multiple) {
  //         // console.log("custom fields", customFieldsData);
  //         // let contactInArray = [];
  //
  //         // contactInArray.push(singlearray);
  //         // console.log(contactInArray);
  //
  //         sectionsWithId[index].fields = customFieldsData;
  //       } else {
  //         sectionsWithId[index].fields = customFieldsData;
  //       }
  //     });
  //
  //     let finaldata = {
  //       formId: form[0]._id,
  //       sections: sectionsWithId,
  //     };
  //
  //     const { data } = await makePostRequest(SENDFORM, finaldata, {
  //       websiteId: merlinWebsite._id,
  //     });
  //     alert("Application Submitted");
  //     setSubmitting(!submitting);
  //   } catch (error) {
  //     alert("Application cancelled");
  //     // openSnackbar(error?.errorMessage ?? 'An error occurred when attempting login.');
  //   }
  // };
  return (
    <div className="max-w-6xl mx-auto px-5">
      {form?.sections?.map((section, index) => {
        const sectionData = data?.find(({ _id }) => _id === section?._id);

        const renderValue = (value) => {
          if (Array.isArray(value)) {
            return value.map((item) => item?.label).join(", ");
          }

          return value?.label ?? value;
        };

        return (
          <div className="mb-6">
            <div className="flex">
              <span className="mb-4 font-bold text-xl">
                Stage {index + 1}: {section?.title}
              </span>
              <div className="bg-white  px-4" style={{ maxWidth: "60px" }}>
                <img
                  style={{ cursor: "pointer" }}
                  src={Edit.publicURL}
                  onClick={() => {
                    handleStep(sectionData?._id);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full flex flex-wrap mb-1 pr-3">
                {section?.multiple ? (
                  <Fragment>
                    {sectionData?.fields?.map((fields) => (
                      <div className="w-full bg-field py-6 pl-8 pr-4 border border-field flex mb-4">
                        <div className="w-full flex flex-wrap h-full">
                          {section?.fields?.map((field) => (
                            <Fragment>
                              <div className="w-full md:w-1/3 mb-4">
                                <span className="font-bold">{field.label}</span>
                                <p>{renderValue(fields?.[field?._id])}</p>
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Fragment>
                ) : (
                  section?.fields?.map((field) => (
                    <Fragment key={field?._id}>
                      <div className="w-full md:w-1/2">
                        <span className="font-bold">{field?.label}</span>
                        {`: `}
                        <span>
                          {renderValue(sectionData?.fields?.[field?._id])}
                        </span>
                      </div>
                    </Fragment>
                  ))
                )}
              </div>
            </div>
          </div>
        );
      })}

      {/*{sections &&*/}
      {/*  BasicInfo?.sections.map((section, index) => {*/}
      {/*    let { title, description, multiple } = sections[index];*/}

      {/*    return (*/}
      {/*      <div className="mb-6">*/}
      {/*        <div className="flex">*/}
      {/*          <span className="mb-4 font-bold text-xl">*/}
      {/*            {description} : {title}*/}
      {/*          </span>*/}
      {/*          <div className="bg-white  px-4" style={{ maxWidth: "60px" }}>*/}
      {/*            <img*/}
      {/*              style={{ cursor: "pointer" }}*/}
      {/*              src={Edit.publicURL}*/}
      {/*              onClick={() => {*/}
      {/*                handleStep(index + 1);*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        {!multiple ? (*/}
      {/*          <div className="flex flex-wrap">*/}
      {/*            {section.map((field) => {*/}
      {/*              let { label } = field;*/}

      {/*              return (*/}
      {/*                <div className="w-full md:w-1/2 mb-1 pr-3">*/}
      {/*                  <span>{label}</span> :*/}
      {/*                  <span>*/}
      {/*                    {Object.values(field).map((value, index) => {*/}
      {/*                      let isArray = value instanceof Array;*/}

      {/*                      return index === 0 && value.length > 0 ? (*/}
      {/*                        isArray ? (*/}
      {/*                          <div className="flex flex-col">*/}
      {/*                            {value.map((item) => (*/}
      {/*                              <div> {item}</div>*/}
      {/*                            ))}*/}
      {/*                          </div>*/}
      {/*                        ) : (*/}
      {/*                          value*/}
      {/*                        )*/}
      {/*                      ) : index != 0 ? (*/}
      {/*                        ""*/}
      {/*                      ) : (*/}
      {/*                        "N/A"*/}
      {/*                      );*/}
      {/*                    })}*/}
      {/*                  </span>*/}
      {/*                </div>*/}
      {/*              );*/}
      {/*            })}*/}
      {/*          </div>*/}
      {/*        ) : (*/}
      {/*          <div className="flex flex-wrap">*/}
      {/*            {section.map((item, index) => {*/}
      {/*              return (*/}
      {/*                <div className="w-full md:w-1/2 md:pr-5 mb-4">*/}
      {/*                  <ContactCard index={index} data={item} />*/}
      {/*                </div>*/}
      {/*              );*/}
      {/*            })}*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}

      <div className="mt-5 flex justify-between flex-wrap">
        <button
          onClick={() => setDeleting(true)}
          className="bg-delete opacity-50 text-white  text-xl py-3 w-full mb-3 md:mb-0 md:w-1/4 px-3"
        >
          Delete Application
        </button>
        <button
          onClick={() => setSubmitting(true)}
          className="bg-primary  text-white  text-xl py-3 w-full mb-3 md:mb-0 md:w-1/4 px-3"
          type="submit"
        >
          Submit Your Application
        </button>
      </div>

      <ConfirmBox // Note : in this example all props are required
        options={{
          icon: false,
          text: "Are you sure you want to delete your application?",
          confirm: "Delete",
          cancel: "Cancel",
          btn: true,
        }}
        isOpen={deleting}
        onClose={() => setDeleting(false)}
        onConfirm={handleReset}
        onCancel={() => setDeleting(false)}
      />
      <ConfirmBox // Note : in this example all props are required
        options={{
          icon: false,
          text: "Are you sure you want to submit your application?",
          cancel: "Cancel",
          confirm: "Submit",
          btn: true,
        }}
        isOpen={submitting}
        onClose={() => setSubmitting(false)}
        onConfirm={(data) => {
          setSubmitting(false);
          onSubmit(data);
        }}
        onCancel={() => setSubmitting(false)}
      />
    </div>
  );
};

export default Summary;
