import React from "react";

const Input = ({
  value,
  name,
  id,
  label,
  placeholder,
  hint,
  optional,
  error,
  handleChange,
  handleBlur,
  textarea,
}) => {
  const props = {
    name,
    value: value ?? "",
    onChange: handleChange,
    onBlur: handleBlur,
    id,
    placeholder,
    className: "w-full py-2 px-2 outline-none",
  };

  return (
    <div className="pt-2 pb-5 px-4 bg-field w-full h-full flex flex-col text-black ">
      <label
        htmlFor={id}
        className={`${
          error && "text-red-800"
        } font-bold mb-1 flex items-center`}
      >
        {label}
        <span className="text-red-800 text-2xl flex items-end">
          {!optional && "*"}
        </span>
      </label>

      {textarea ? <textarea {...props} /> : <input {...props} />}
      <p className="mt-1 text-sm text-fieldtext">{hint}</p>
    </div>
  );
};

export default Input;
