import React, { useState, useEffect, Fragment } from "react";
import ConfirmBox from "react-dialog-confirm";
import { graphql } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { isAfter, parseISO } from "date-fns";

import Layout from "@components/layout";
import SEO from "@components/seo";
import { setForm, resetForm } from "@actions/form";
import { bakeLocalStorage, readLocalStorage } from "@helpers/storage";

import Start from "@components/Form/Start";
import Summary from "@components/Form/Summary";
import Heading from "@components/Form/Heading";
import Section from "@components/Form/Section";
import usePrevious from "@helpers/hooks/usePrevious";
import { clone } from "lodash";
import { makePostRequest } from "@helpers/requests";
import { FORM } from "@helpers/api";
import { withSnackbar } from "react-simple-snackbar";

const Home = ({ data: { form }, openSnackbar }) => {
  const dispatch = useDispatch();
  const data = useSelector(({ form }) => form);
  // get last available section id if exists otherwise first section id
  const [currentStep, setCurrentStep] = useState(
    !!data
      ? form?.sections?.length === data?.length
        ? form?.sections[form?.sections?.length - 1]?._id
        : form?.sections[data?.length]?._id
      : form?.sections[0]?._id
  );

  const [deleting, setDeleting] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [started, setStarted] = useState(!!data);
  const [finishing, setFinishing] = useState(false);
  const [completed, setCompleted] = useState(false);
  // currentStepIndex helps the app to work out what form section is currently active, this is only applicable to
  // swiper and forms, not our own form data (this could be any index).
  const currentStepIndex = form?.sections.findIndex(
    ({ _id }) => _id === currentStep
  );

  const handleReset = () => {
    setStarted(false);
    setDeleting(false);
    setFinishing(false);
    dispatch(resetForm());
    setCurrentStep(form?.sections[0]?._id);
  };

  const handleNextStep = ({ _id: sectionId, fields } = {}) => {
    if (sectionId) {
      // set new form data, remove and re-add section if already existed
      dispatch(
        setForm([
          ...(data || []).filter(({ _id }) => _id !== sectionId),
          {
            _id: sectionId,
            fields,
          },
        ])
      );
    }

    // no more next steps, show form summary
    if (currentStepIndex === form?.sections?.length - 1) {
      setFinishing(true);
      swiper.slideTo(currentStepIndex + 1);
    } else {
      setFinishing(false);
      setCurrentStep(form?.sections[currentStepIndex + 1]?._id);
    }
  };

  const handleSubmit = async () => {
    try {
      const postData = {
        formId: form?._id,
        sections: data.map((section) => {
          const formSection = form?.sections?.find(
            ({ _id }) => _id === section?._id
          );

          let sectionData = {
            _id: section?._id,
            fields: [],
          };

          const renderMultiple = (fields) => {
            const fieldsData = [];

            for (let id in fields) {
              if (fields.hasOwnProperty(id)) {
                fieldsData.push({
                  _id: id,
                  value: fields[id],
                });
              }
            }

            return fieldsData;
          };

          for (let id in section?.fields) {
            if (section?.fields.hasOwnProperty(id)) {
              sectionData.fields.push(
                formSection?.multiple
                  ? renderMultiple(section?.fields?.[id])
                  : {
                      _id: id,
                      value: section?.fields?.[id],
                    }
              );
            }
          }

          return sectionData;
        }),
      };

      const { data: formData } = await makePostRequest(FORM, postData);
      handleReset();
      setCompleted(true);
      openSnackbar("Your application form was successfully submitted.");
    } catch (error) {
      error !== "cancelled" &&
        openSnackbar(
          error?.errorMessage ??
            "An error occurred submitting this form, please try clearing your cache or try again later."
        );
    }
  };

  useEffect(() => {
    if (finishing) {
      setCurrentStep(0);
    }
  }, [finishing]);

  const handlePrevStep = () => {
    if (finishing) {
      setFinishing(false);
      setCurrentStep(form?.sections[form?.sections?.length - 1]?._id);
      return;
    }

    setCurrentStep(form?.sections[currentStepIndex - 1]?._id);
  };

  //slide to section when currentStep changes
  useEffect(() => {
    if (!currentStep || !swiper) return;
    if (!!currentStepIndex) {
      swiper.slideTo(currentStepIndex);
    }
  }, [currentStepIndex, swiper]);

  useEffect(() => {
    if (!!swiper) {
      if (form?.sections?.length === data?.length) {
        swiper.slideTo(form?.sections?.length);
        setFinishing(true);
        setCurrentStep(0);
      }
    }
  }, [swiper]);

  const requiredFields = form?.sections?.reduce(
    (acc, curr) => [
      ...acc,
      ...(curr?.fields ?? []).filter((field) => {
        return (
          ((curr?.multiple && curr?.min >= 1) || !curr?.multiple) &&
          !field.optional
        );
      }),
      // .reduce(
      //   (fieldAcc, field) =>
      //     curr?.multiple && curr?.min > 1
      //       ? [
      //           ...fieldAcc,
      //           ...Array(curr?.min)
      //             .fill()
      //             .map((x) => field),
      //         ]
      //       : [...fieldAcc, field],
      //   []
      // ),
    ],
    []
  );

  const completedRequiredFields =
    data?.reduce(
      (acc, curr) => ({
        ...acc,
        ...(() => {
          const completedFields = {};
          const section = form?.sections?.find(
            (section) => section?._id === curr?._id
          );

          if (section?.multiple) {
            if (curr?.fields) {
              for (let fields of curr?.fields) {
                for (let id in fields) {
                  if (fields.hasOwnProperty(id)) {
                    if (
                      requiredFields.some(
                        (requiredField) => requiredField?._id === id
                      )
                    ) {
                      completedFields[id] = fields[id];
                    }
                  }
                }
              }
            }
          } else {
            for (let fieldId in curr?.fields) {
              if (curr?.fields.hasOwnProperty(fieldId)) {
                if (
                  requiredFields.some(
                    (requiredField) => requiredField?._id === fieldId
                  )
                ) {
                  completedFields[fieldId] = curr?.fields[fieldId];
                }
              }
            }
          }

          return completedFields;
        })(),
      }),
      {}
    ) ?? {};

  const percentage = parseInt(
    (Object.keys(completedRequiredFields).length * 100) / requiredFields.length
  );

  useEffect(() => {
    const updatedTimestamp = readLocalStorage("formUpdatedTimestamp");

    // if the current form is different from the updatedTimestamp (last form timestamp stored) then reset the form data.
    if (
      updatedTimestamp &&
      isAfter(
        parseISO(form?.meta?.updatedTimestamp),
        parseISO(updatedTimestamp)
      )
    ) {
      handleReset();
    }

    bakeLocalStorage("formUpdatedTimestamp", form?.meta?.updatedTimestamp);
  }, []);

  useEffect(() => {
    if (started) {
      setCompleted(false);
    }
  }, [started]);

  return (
    <Layout
      completedPercentage={percentage}
      currentStep={currentStep}
      currentStepIndex={currentStepIndex}
      started={started}
      onStart={() => setStarted(true)}
    >
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Application"
      />

      {!started ? (
        <Start
          form={form}
          handleStart={() => setStarted(true)}
          completed={completed}
        />
      ) : (
        <Fragment>
          <Heading
            completedPercentage={percentage}
            setSwiper={setSwiper}
            form={form}
            data={data}
            currentStep={currentStep}
            currentStepIndex={currentStepIndex}
            handlePrevStep={handlePrevStep}
            handleNextStep={handleNextStep}
          />

          {form.sections.some(({ _id }) => _id === currentStep) ? (
            form?.sections?.map(
              (section, index) =>
                section._id === currentStep && (
                  <Section
                    key={section._id}
                    section={form?.sections[currentStepIndex]}
                    data={data?.find(({ _id }) => _id === currentStep)}
                    handleNextStep={handleNextStep}
                    handleDelete={() => setDeleting(true)}
                  />
                )
            )
          ) : (
            <Summary
              handleReset={handleReset}
              handleStep={(id) => {
                setCurrentStep(id);
              }}
              form={form}
              onSubmit={handleSubmit}
            />
          )}
        </Fragment>
      )}

      <ConfirmBox
        options={{
          icon: false,
          text: "Are you sure you want to delete your application?",
          confirm: "yes",
          cancel: "no",
          btn: true,
        }}
        isOpen={deleting}
        onClose={() => setDeleting(!deleting)}
        onConfirm={handleReset}
        onCancel={() => setDeleting(!deleting)}
      />
    </Layout>
  );
};

export default withSnackbar(Home);

export const query = graphql`
  query {
    form: merlinForm(_id: { eq: "5f0ca41ab6bc56dd41f74b34" }) {
      _id
      name
      description
      meta {
        updatedTimestamp
      }
      sections {
        _id
        title
        description
        multiple
        min
        max
        fields {
          label
          hint
          optional
          options {
            _id
            label
          }
          type
          placeholder
          id
          _id
        }
      }
    }
  }
`;
