import React from "react";
const Start = ({ form, handleStart, completed }) => {
  const importants = [
    "Know which course you want to apply for",
    " Your National Insurance number",
    "One or two parent/carers contact details (such as names and addresses)",
    "Two or more emergency contact details (can’t be the same as the parent/carer used above)",
    "Your GP’s name and contact details",
  ];
  return (
    <div className="max-w-6xl mx-auto px-5">
      <div className="py-12 w-full md:w-4/5">
        {completed && (
          <div className="py-12 w-full md:w-4/5">
            <div
              className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
              role="alert"
            >
              <div className="flex">
                <div>
                  <p className="font-bold">
                    Your application form was successfully submitted
                  </p>
                  <p className="text-sm">
                    You should expect to hear a response from us soon.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="text-md md:text-xl  font-bold text-black">
          Welcome to the Application Form for the{" "}
          <a
            href="https://www.wstraining.co.uk/study-programme"
            target="_blank"
            rel="noopener"
          >
            <span className="text-primary underline">Study Programme</span>
          </a>
        </div>
        <p className="mt-8 text-md md:text-xl text-black font-medium">
          This application consists of{" "}
          <span className="font-bold">
            {(form?.sections?.length ?? 1) + 1} stages{" "}
          </span>{" "}
          and will take{" "}
          <span className="font-bold">
            approximately 30 minutes to complete
          </span>{" "}
          if you have all your information to hand already and you know which
          course you would like to apply for. You can apply as the student
          directly, or you can fill this application form out on behalf of the
          student if are their parent or carer.
        </p>
        <div className="mt-8">
          <div className="text-md md:text-xl  font-bold text-black">
            Important information that you should have before you start:
          </div>
          <ul>
            {importants.map((important) => {
              return (
                <li key={important} className="text-md md:text-xl  text-black">
                  - {important}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="mt-8 text-md md:text-xl  text-black">
          This portal will save your progress as you move forward on each step
          to your local storage on your web browser. Which means you can come
          back later if you wish as long as you’re not on a shared computer. You
          have the option at any time to remove the locally saved data. Please
          note, we will not receive your application until all stages are
          completed and you receive a confirmation email.
        </div>
        <div className="mt-8 text-md md:text-xl  text-black">
          {" "}
          To see how your information and application is stored please our{" "}
          <span className="underline">privacy policy.</span>
        </div>
      </div>

      <button
        className="bg-primary w-full md:w-1/3 py-4 mt-8 text-white  text-md md:text-xl "
        onClick={handleStart}
      >
        Start Application
      </button>
    </div>
  );
};
export default Start;
